import React, {Component} from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { Layout } from '../layout/Layout';

class About extends Component {
    render() {
        return (
            <>
                <Layout>
                    <Header active="about"/>

                    <section className="py-5 container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-10 mx-auto">
                                <h1 className="font-weight-light"><span role="img" aria-label="">🦸‍♂️</span> About Me</h1>
                                <p className="jumbotron-text">
                                    I am <a className="highlight" href="#designer">designer</a>, <a className="highlight" href="#maker">maker</a> and <a className="highlight" href="#drone">drone photographer</a>!
                                </p>
                            </div>
                        </div>
                    </section>

                    <div className="container" id="designer">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-10">
                                <img src="/portfolio/my-potrait.jpeg" className="col-md-5 col-lg-3 float-md-left mb-4 mr-md-5 rounded img-fluid shadow" alt=""/>
                                <h2 className="mt-0">Designer</h2>
                                <p className="mt-2">I am working as a Senior Product Designer at an enterprise mortgage software company Ellie Mae trying to simplify loan processes for lenders. If you think taking a mortgage is a headache, what happens next will blow your mind.</p>           
                                <h3 className="mt-4">Tech solutions made human</h3>
                                <p className="mt-2">I enjoy solving complex problems through design. I am passionate about emerging technologies and looping the “human” into tech innovations. I have worked on AI + Design to simplify loan processes, designed an ai-based instant messaging app for police officers on active duty, and envisioned the future of policing with autonomous police cars.</p>                  
                            </div>
                        </div>
                    </div>

                    {/* <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-3">
                                <img src="/portfolio/my-potrait.jpeg" className="rounded img-fluid shadow" alt=""/>
                            </div>
                            <div className="col-12 col-md-1"></div>
                            <div className="col-12 col-md-6">
                                <h2 className="mt-0">Designer</h2>
                                <p className="mt-2">I am working as a UX Designer at an enterprise mortgage software company Ellie Mae trying to simplify loan processes for lenders. If you think taking a mortgage is a headache, what happens next will blow your mind.</p>           
                                <h3 className="mt-5">Tech solutions made human</h3>
                                <p className="mt-2">I enjoy solving complex problems through design. I am passionate about emerging technologies and looping the “human” into tech innovations. I have worked on AI + Design to simplify loan processes, designed an ai-based instant messaging app for police officers on active duty, and envisioned the future of policing with autonomous police cars.</p>                     
                            </div>
                        </div>
                    </div> */}
                    
                    <div className="container">
                        <div className="row justify-content-md-center">
                            {/* <div className="project-content col-12 col-lg-10">
                                <img src="/portfolio/my.png" style={{"width" : "100%"}} alt="" id="designer"/>
                                <p className="mt-2">I am working as a UX Designer at an enterprise mortgage software company Ellie Mae trying to simplify loan processes for lenders. If you think taking a mortgage is a headache, what happens next will blow your mind.</p>                     
                                <h3>Tech solutions made human</h3>
                                <p>I enjoy solving complex problems through design. I am passionate about emerging technologies and looping the “human” into tech innovations. I have worked on AI + Design to simplify loan processes, designed an ai-based instant messaging app for police officers on active duty, and envisioned the future of policing with autonomous police cars.</p>
                            </div>*/}

                            <div className="col-12 col-lg-10" id="maker">
                                <h2>Maker <span role="img" aria-label="">🛠</span></h2>
                                <img src="/portfolio/maker.png" className="img-fluid mt-2" alt=""/>
                                <p className="mt-2">Outside of work, I teach and learn 3D printing and basic electronics at a community maker space Robot Garden. Recently, I also wrapped a successful Kickstarter campaign around laser cut animal magnets. Now I am on to making a working Mars Rover replica.</p>                     
                            </div>

                            <div className="col-12 col-lg-10" id="drone">
                                <h2>Drone Photography <span role="img" aria-label="">📷</span></h2>
                                <img src="/portfolio/drone.png" className="img-fluid mt-2" alt="" />
                                <p className="mt-2">At the moment I am exploring the realms of the sky by dabbling with drone photography. I have traveled to Alaska, different parts of California, and remote villages of India with it.</p>                     
                            </div>
                        </div>

                        {/* <div className="row justify-content-md-center">
                            <div className="col-12 col-md-4">
                                <h3>And stuff…</h3>
                                <img src="/portfolio/my.png" style={{"width" : "100%"}} alt=""/>
                                <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vehicula finibus suscipit. Praesent commodo id risus eu viverra. Proin maximus massa et risus posuere dapibus.</p>                     
                            </div>
                            <div className="col-12 col-md-4">
                                <h3>Currently geeking about</h3>
                                <img src="/portfolio/my.png" style={{"width" : "100%"}} alt="" />
                                <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vehicula finibus suscipit. Praesent commodo id risus eu viverra. Proin maximus massa et risus posuere dapibus.</p>                     
                            </div>
                        </div> */}
                    </div>
                    <Footer />
                </Layout>
            </>
        )
    }
}

export default About