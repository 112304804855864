import React, {Component} from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeClass: '',
        };
        this.scrollFunction = this.scrollFunction.bind(this)
    }
    
    scrollFunction () {
        let activeClass = 'normal';
        if(window.scrollY >= 20){
            activeClass = 'nav-scroll';
        }
        this.setState({ activeClass });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollFunction);
    }

    render() {
        return (
            <nav className={`navbar navbar-expand-sm navbar-light sticky-top p-0 ${this.state.activeClass}`}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">mehul.</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className={`nav-link ${this.props.active === "home" ? 'active' : ''}`} href="/" title="work">work</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${this.props.active === "experiments" ? 'active' : ''}`} href="/experiments" title="side projects">side projects</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${this.props.active === "about" ? 'active' : ''}`} href="/about" title="about">about</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${this.props.active === "resume" ? 'active' : ''}`} href="/resume" title="resume">resume</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header