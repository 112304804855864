import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className="container-fluid text-center border-top mt-5 bg-light">
                <p className="mb-1 mt-4">Curious to hear more. Let's Connect.</p>
                <p className="mb-4">
                    <a className="" href="https://www.linkedin.com/in/mehulagr" target="_blank" rel="noreferrer">LinkedIn</a>
                        <span className="px-2">/</span>
                    <a className="" href="https://medium.com/@mehul_agr" target="_blank" rel="noreferrer">Medium</a>
                </p>
                <p className="mb-1 pb-4">
                    Hand coded with ♥︎
                </p>
            </div>
        )
    }
}

export default Footer;